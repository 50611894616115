export default {
  'original_password': 'Mật khẩu gốc',
  'new_password': 'Mật khẩu mới',
  'enter_the_original_password': 'Nhập mật khẩu',
  'enter_the_original_password_login':'Nhập mật khẩu gốc',
  'enter_the_new_password_(enter_8_or_more_characters)': 'Nhập mật khẩu mới (nhập 8 ký tự trở lên)',
  'confirm_password': 'Xác nhận mật khẩu',
  're_enter_the_new_password': 'Nhập mật khẩu mới',
  'confirm_modification': 'Nhập lại mật khẩu mới',
  'enter_new_password_enter_6_digit_number': 'Nhập mật khẩu mới (nhập số gồm 6 chữ số)',
  'recharge_amount': 'Số tiền nạp lại',
  'reason_for_rejection': 'Lý do từ chối',
  'time': 'Thời gian',
  'in_review': 'Đang kiểm tra',
  'completed': 'Hoàn thành',
  'rejected': 'Từ chối',
  'current_browser_does_not_support_copy': 'Trình duyệt hiện tại không hỗ trợ sao chép',
  'copy_successful': 'Sao chép thành công',
  'home': 'Trang chủ',
  'liquidity_pool': 'Thanh Khoản',
  'me': 'Cá nhân',
  'recharge': 'Nạp tiền',
  'withdraw': 'Rút tiền',
  'buy_second_contract': 'Mua hợp đồng thứ hai',
  'settle_second_contract': 'Giải quyết hợp đồng thứ hai',
  'buy_lever': 'Mua đòn bẩy',
  'settle_lever': 'Đòn bẩy ổn định',
  'stake_lp': 'Cổ phần LP',
  'earnings_lp': 'Thu nhập LP',
  'settlement_lp': 'Giải quyết LP',
  'admin': 'Nạp tiền cá nhân',
  'withdraw_amount': 'Số tiền rút',
  'withdrawal_address': 'Địa chỉ rút tiền',
  'rejection_reason': 'Lý do từ chối',
  'under_review': 'Đang xem xét',
  'invitation_code': 'Mã mời',
  'balance': 'Sự cân bằng',
  'today': 'Hôm nay',
  'deposit': 'Nạp tiền',
  'real_name_authentication': 'Xác thực tên thật',
  'billing_history': 'Lịch sử thanh toán',
  'second_contract_order': 'Lịch sử giao dịch',
  'liquidity_pool_order': 'Hợp đồng ký quỹ',
  'my_team': 'Nhóm của tôi',
  'change_password': 'Thay đổi mật khẩu',
  'logout': 'Đăng xuất',
  'cancel': 'Hủy bỏ',
  'document_type': 'Loại giấy tờ',
  'real_name': 'Tên thật',
  'please_enter_real_name': 'Vui lòng nhập tên thật',
  'please_enter_document_number': 'Vui lòng nhập số tài liệu',
  'document_number': 'Số giấy tờ',
  'document_photo': 'Ảnh giấy tờ',
  'front': 'Mặt trước',
  'back': 'Mặt sau',
  'submit': 'Gửi',
  'certification_passed': 'Xác nhận thành công',
  'identity_card': 'Thẻ căn cước',
  'passport': 'Hộ chiếu',
  'login_password': 'Mật khẩu đăng nhập',
  'transaction_password': 'Mật khẩu giao dịch',
  'low': 'Thấp',
  'high': 'Cao',
  'open': 'Giá ban đầu',
  'close': 'Đóng',
  'buy_rise': 'Mua tăng',
  'buy_fall': 'Mua giảm',
  'return_rate': 'Tỷ lệ hoàn trả',
  'capital': 'Trung tâm',
  'is_leverage': 'Đòn bẩy',
  'leverage_ratio': 'Tỷ lệ đòn bẩy',
  'amount': 'Số lượng',
  'available_amount': 'Số lượng có sẵn',
  'expected_return': 'Lợi nhuận mong đợi',
  'transaction_fee': 'Phí giao dịch',
  'confirmation': 'Xác nhận',
  'price': 'Giá',
  'direction': 'Phương hướng',
  'rise': 'Tăng',
  'fall': 'Giảm',
  'enter_payment_password': 'Nhập mật khẩu thanh toán',
  'in_progress': 'Đang tiến hành',
  'final_price': 'Giá cuối cùng',
  'leverage': 'Đòn bẩy',
  'handling_fee': 'Chờ xử lý',
  'expected_profit_loss': 'Lợi nhuận/Lỗ dự kiến',
  'creation_time': 'Thời gian sáng tạo',
  'no_more': 'Không còn',
  'has_ended': 'Kết thúc',
  'actual_profit_and_loss': 'Lợi nhuận và lỗ thực tế',
  'end_time': 'Thời gian kết thúc',
  'billing_record': 'Hồ sơ thanh toán',
  'currency_pair': 'Cặp tiền tệ',
  'latest_price': 'Giá mới nhất',
  'price_change_percentage': 'Tỷ lệ thay đổi giá',
  'staking_liquidity_pool': 'Hợp đồng ký quỹ',
  'participate_in_staking': 'Mua hợp đồng',
  'current_total_staking': 'Tổng số tiền mua hiện tại',
  'cumulative_rewards': 'Phần thưởng tích lũy',
  'add_liquidity': 'Thêm thanh khoản',
  'daily_yield_rate': 'Tỷ lệ lợi nhuận hàng ngày',
  'staking_limit': 'Giới hạn mua',
  'day': 'Ngày',
  'staking_time': 'Thời gian ký quỹ',
  'input_staking_amount': 'Số tiền đặt cược đầu',
  'staking_amount': 'Số lượng ký quỹ',
  'total_return_rate': 'Tổng tỷ lệ lợi nhuận',
  'confirm': 'Xác nhận',
  'liquidity_pool_staking_order': 'Lệnh đặt cược nhóm thanh khoản',
  'daily_profit': 'Lợi nhuận hằng ngày',
  'due_date': 'Ngày đến hạn',
  'order_id': 'ID đặt hàng',
  'expiration_time': 'Thời gian hết hạn',
  'cancel_order': 'Hủy đơn hàng',
  'confirm_cancellation': 'Xác nhận hủy bỏ',
  'penalty_rate': 'Tỷ lệ phạt',
  'expected_arrival_amount': 'Số lượng dự kiến ',
  'login_account': 'Đăng nhập tài khoản',
  'input_email_account': 'Nhập tài khoản Email',
  'email': 'Email',
  'password': 'Mật khẩu',
  'input_login_password': 'Nhập mật khẩu đăng nhập',
  'login': 'Đăng nhập',
  'register_now': 'Đăng ký ngay',
  'no_account_yet': 'Chưa có tài khoản',
  'account': 'Tài khoản',
  'invitation_time': 'Thời gian mời',
  'level': 'Mức độ',
  'please_select_withdrawal_currency': 'Tiền tệ rút tiền',
  'withdrawal_amount': 'Số tiền rút',
  'please_enter_withdrawal_amount': 'Vui lòng nhập số tiền rút',
  'please_enter_withdrawal_address': 'Vui lòng nhập địa chỉ rút tiền',
  'please_enter_transaction_password': 'Vui lòng nhập mật khẩu giao dịch',
  'estimated_actual_arrival': 'Dự kiến ​​thực tế đến',
  'please_select_recharge_currency': 'Loại tiền tệ ',
  'recharge_address': 'Địa chỉ nạp tiền',
  'please_enter_recharge_amount': 'Vui lòng nhập số tiền nạp',
  'please_enter_recharge_hash': 'Vui lòng nhập địa chỉ',
  'recharge_hash': 'Nạp tiền',
  'upload_recharge_proof_payment_screenshot': 'Tải lên hình ảnh nạp tiền (Ảnh chụp màn hình thanh toán)',
  "please_authenticate_first": "Vui lòng xác thực trước",
  "create_account": "Tạo tài khoản",
  "verification_code": "Mã xác minh",
  "enter_verification_code": "Nhập mã xác minh",
  "get_verification_code": "Nhận mã xác minh",
  "funds_password": "Mật khẩu quỹ",
  "register": "Đăng ký",
  "set_funds_password_6_digit_number": "Đặt mật khẩu quỹ (số gồm 6 chữ số)",
  "set_login_password_input_8_plus_characters": "Đặt mật khẩu đăng nhập (nhập 8 ký tự)",
  "please_enter_email": "Vui lòng nhập email",
  "announcement": "Thông báo",
  "announcement_details": "Chi tiết thông báo",
  "times": "Thời gian",
  "the_purchase_period_has_ended_please_make_a_new_selection": "Thời gian mua hàng đã kết thúc, vui lòng thực hiện lựa chọn mới",

  "index": {
    button: "Bắt đầu giao dịch",
    policy: "Uniswap Platform Policy",
    banTip: "Cho phép bạn diễn ra chứng khoán của bạn bằng cách mua và bán những kim cương đặc sắc của chúng tôi. Chúng tôi cung cấp dịch vụ mua và bán kim cương và tiền mặt.",
    tip1: "Kim cương và tiền mặt có thể diễn ra chứng khoán và bảo vệ bạn trên chơi cổ phần nguyên liệu.",
    tip2: "Những khách hàng mua bán có thể tạo thêm doanh thu trong thời gian ngắn hoặc dài từ thị trường vàng và tiền.",
    tip3: "Hệ thống không khóa cùng với chức năng chơi với động cơ của chúng tôi, có thể giúp bạn mua và bán kim cương hoặc tiền nhỏ hơn.",
    tip4: "Công ty này có đội ngũ chuyên môn cực kỳ chuyên nghiệp, tranh cử nhanh chóng và rõ ràng!",
  },
  "newHome": {
    Cryptos: "Tiền mã hóa",
    Forex: "Ngoại hối",
    Gold: "Vàng",
    futures: "Futures",

  },
  "newPool": {
    StartStaking: "Bắt đầu"
  },
  "newMe": {
    balance: "Số dư tài khoản",
    saveQr: "Lưu mã QR",
    TopUp: "Phần thưởng nạp tiền",
  },
  newLevel: "Mức độ",
  Cumulative: "Tổng số tiền tích lũy hiện tại",
  levelSets: "Nâng cấp nạp tiền",
  kefu: "Liên hệ với bộ phận chăm sóc khách hàng",
  levelInfo: {
    infoLv1: "Mở khóa kênh VIP để nạp tiền và rút tiền",
    infoLv2: "Mở khóa vàng phúc lợi VIP hàng tháng là 888",
    infoLv3: "Tận hưởng thu nhập cố định hàng ngày là 0,001 từ số dư ví nền tảng",
    toUpUsd: "{name} Mục tiêu nạp tiền"
  },
  News: "Tin tức",

  StayTuned: "Hãy theo dõi",
  develop: "Đang trong quá trình phát triển, hãy theo dõi nhé ",
  "赠送": "Tặng quà",
  "大礼包": "Gói quà tặng",
  "联系客服领取": "Dịch vụ khách hàng",

  Gold:"Vàng",
  Forex:"Ngoại hối",
  Futures:"Chứng khoán",
  Cryptos:"Tiền điện tử",

  meCard: "Thẻ ngân hàng của tôi",
  cardAdmin:"Quản lý thẻ ngân hàng",
  newCard: {
    cardName:'tên ngân hàng',
    cardNamePla:'Xin vui lòng nhập tên ngân hàng',
    number:'Tài khoản người nhận thanh toán',
    numberPla:'Vui lòng nhập số tài khoản người nhận',
    name:'Tên người nhận',
    namePla:'Vui lòng nhập tên người nhận',
    save:'Lưu',
    balance:"Số dư tài khoản",
    cardInfos:'Thông tin thẻ ngân hàng',
    customer_service:'Vui lòng liên hệ bộ phận chăm sóc khách hàng, thời gian rút tiền: 11:00 - 23:00',
    addCardInfo:'Vui lòng thêm thông tin thẻ ngân hàng trước'

  },
  status:"Trạng thái",
  Order:"Đặt hàng",

  banTip2: "Nó là một dự án nguồn mở, thuộc danh mục sản phẩm DeFi, vì nó sử dụng hợp đồng thông minh để thúc đẩy giao dịch thay vì sàn giao dịch tập trung, đoạn này có thể thêm vào trang giới thiệu về hồ đột hợp.",
  langeSet: "Chọn ngôn ngữ",
  Sure: "Chắc chắn",
  createAcount: {
    have: 'Đã có tài khoản',
    goLogin: 'Đi đến đăng nhập'
  },
  Name: 'Tên',
  total_revenue: 'Tổng tỷ lệ lợi nhuận',
  Return: "Trở về",
  personal: "Trung tâm cá nhân",
  operate: "thao tác",
  information: "thông tin"
}
