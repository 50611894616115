import { createApp } from 'vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue';
import { router } from './router';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'vant/lib/index.css';
import i18n from "./locales/index";
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import './assets/css.css'
import Public from "@/utils/pubilc";

Locale.use('en-US', enUS);
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App);
app.config.globalProperties.$public = Public
app.use(pinia);
app.use(ElementPlus);
app.use(i18n);
app.use(router);
app.mount('#app');
