export default {
  'original_password': 'Password originale',
  'new_password': 'Nuova password',
  'enter_the_original_password': 'Inserisci la password originale',
  'enter_the_original_password_login': 'Inserisci la password originale',
  'enter_the_new_password_(enter_8_or_more_characters)': 'Inserisci la nuova password (inserisci 8 o più caratteri)',
  'confirm_password': 'Conferma la password',
  're_enter_the_new_password': 'Ripeti la nuova password',
  'confirm_modification': 'Conferma la modifica',
  'enter_new_password_enter_6_digit_number': 'Inserisci la nuova password (inserisci un numero a 6 cifre)',
  'recharge_amount': 'Importo da ricaricare',
  'reason_for_rejection': 'Motivo del rifiuto',
  'time': 'ora',
  'in_review': 'In revisione',
  'completed': 'Completato',
  'rejected': 'Rifiutato',
  'current_browser_does_not_support_copy': 'Il browser attuale non supporta la copia',
  'copy_successful': 'Copia riuscita',
  'home': 'Home',
  'liquidity_pool': 'Pool di liquidità',
  'me': 'Io',
  'recharge': 'Ricarica',
  'withdraw': 'Preleva',
  'buy_second_contract': 'Compra secondo contratto',
  'settle_second_contract': 'Solve secondo contratto',
  'buy_lever': 'Buy Lever',
  'settle_lever': 'Settle Lever',
  'stake_lp': 'Stake LP',
  'earnings_lp': 'Giocatori LP',
  'settlement_lp': 'Settlement LP',
  'admin': 'Premi di livello',
  'withdraw_amount': 'Importo da prelevare',
  'withdrawal_address': 'Indirizzo di prelievo',
  'rejection_reason': 'Motivo del rifiuto',
  'under_review': 'In revisione',
  'invitation_code': 'Codice di invito',
  'balance': 'Saldo',
  'today': 'Oggi',
  'deposit': 'Deposito',
  'real_name_authentication': 'Autenticazione con nome reale',
  'billing_history': 'Cronologia fatturazione',
  'second_contract_order': 'Ordine del secondo contratto',
  'liquidity_pool_order': 'Ordine del pool di liquidità',
  'my_team': 'Mio team',
  'change_password': 'Cambia password',
  'logout': 'Esci',
  'cancel': 'Annulla',
  'document_type': 'Tipo di documento',
  'real_name': 'Nome reale',
  'please_enter_real_name': 'Inserisci il nome reale',
  'please_enter_document_number': 'Inserisci il numero del documento',
  'document_number': 'Numero del documento',
  'document_photo': 'Foto del documento',
  'front': 'Ante',
  'back': 'Retro',
  'submit': 'Invia',
  'certification_passed': 'Certificazione superata',
  'identity_card': 'Carta d\'identità',
  'passport': 'Passaporto',
  'login_password': 'Password di accesso',
  'transaction_password': 'Password di transazione',
  'low': 'Basso',
  'high': 'Alto',
  'open': 'Aperto',
  'close': 'Chiuso',
  'buy_rise': 'Acquista salita',
  'buy_fall': 'Acquista caduta',
  'return_rate': 'Tasso di rendimento',
  'capital': 'Capitale',
  'is_leverage': 'È leva',
  'leverage_ratio': 'Rapporto di leva',
  'amount': 'Importo',
  'available_amount': 'Importo disponibile',
  'expected_return': 'Ritorno atteso',
  'transaction_fee': 'Commissione di transazione',
  'confirmation': 'Conferma',
  'price': 'Prezzo',
  'direction': 'Direzione',
  'rise': 'Rise',
  'fall': 'Fall',
  'enter_payment_password': 'Inserisci la password di pagamento',
  'in_progress': 'In corso',
  'final_price': 'Prezzo finale',
  'leverage': 'Leverage',
  'handling_fee': 'Commissione di gestione',
  'expected_profit_loss': 'Guadagno/perdita attesa',
  'creation_time': 'Ora di creazione',
  'no_more': 'No More',
  'has_ended': 'Has Ended',
  'actual_profit_and_loss': 'Guadagno e perdita attuale',
  'end_time': 'Ora di fine',
  'billing_record': 'Registro fatturazione',
  'currency_pair': 'Coppia di valute',
  'latest_price': 'Ultimo prezzo',
  'price_change_percentage': 'Percentuale di variazione del prezzo',
  'staking_liquidity_pool': 'impegno',
  'participate_in_staking': 'Partecipa a staking',
  'current_total_staking': 'Staking totale attuale',
  'cumulative_rewards': 'Ricompense cumulate',
  'add_liquidity': 'Aggiungi liquidità',
  'daily_yield_rate': 'Tasso di rendimento giornaliero',
  'staking_limit': 'Limite di staking',
  'day': 'Giorno',
  'staking_time': 'Ora di staking',
  'input_staking_amount': 'Importo di staking da inserire',
  'staking_amount': 'Importo di staking',
  'total_return_rate': 'Tasso di ritorno totale',
  'confirm': 'Conferma',
  'liquidity_pool_staking_order': 'Ordine di staking del pool di liquidità',
  'daily_profit': 'Guadagno giornaliero',
  'due_date': 'Data di scadenza',
  'order_id': 'ID ordine',
  'expiration_time': 'Ora di scadenza',
  'cancel_order': 'Annulla ordine',
  'confirm_cancellation': 'Conferma l\'annullamento',
  'penalty_rate': 'Tasso di penale',
  'expected_arrival_amount': 'Importo di arrivo previsto',
  'login_account': 'Account di accesso',
  'input_email_account': 'Inserisci l\'account email',
  'email': 'Email',
  'password': 'Password',
  'input_login_password': 'Inserisci la password di accesso',
  'login': 'Accedi',
  'register_now': 'Registrati ora',
  'no_account_yet': 'Non hai ancora un account',
  'account': 'Account',
  'invitation_time': 'Orario dell\'invito',
  'level': 'Livello',
  'please_select_withdrawal_currency': 'Scegli la valuta di prelievo',
  'withdrawal_amount': 'Importo di prelievo',
  'please_enter_withdrawal_amount': 'Inserisci l\'importo di prelievo',
  'please_enter_withdrawal_address': 'Inserisci l\'indirizzo di prelievo',
  'please_enter_transaction_password': 'Inserisci la password di transazione',
  'estimated_actual_arrival': 'Arrivo stimato',
  'please_select_recharge_currency': 'Seleziona la valuta di ricarica',
  'recharge_address': 'Indirizzo di ricarica',
  'please_enter_recharge_amount': 'Inserisci l\'importo di ricarica',
  'please_enter_recharge_hash': 'Inserisci il hash di ricarica',
  'recharge_hash': 'Hash di ricarica',
  'upload_recharge_proof_payment_screenshot': 'Carica la prova di ricarica (screenshot del pagamento)',
  'please_authenticate_first': 'Autentica per primo',
  'create_account': 'Crea un account',
  'verification_code': 'Codice di verifica',
  'enter_verification_code': 'Inserisci il codice di verifica',
  'get_verification_code': 'Ottieni il codice di verifica',
  'funds_password': 'Password dei fondi',
  'register': 'Registrati',
  'set_funds_password_6_digit_number': 'Imposta la password dei fondi (numero a 6 cifre)',
  'set_login_password_input_8_plus_characters': 'Imposta la password di accesso (inserisci 8 o più caratteri)',
  'please_enter_email': 'Inserisci la tua email',
  "announcement": "Annuncio",
  "announcement_details": "Dettagli dell'annuncio",
  "times": "Volte",
  "the_purchase_period_has_ended_please_make_a_new_selection": "Il periodo di acquisto è terminato, effettua una nuova selezione",
  "index": {
    button: "Start Trading",
    policy: "Uniswap Platform Policy",
    banTip: "Uniswap è un trading a quote fisse sulla blockchain, in cui la tua vincita o perdita dipende dal fatto che il prezzo medio della valuta digitale al momento della scadenza sia più alto o più basso del prezzo della transazione al momento dell'acquisto.",
    tip1: "Tempo minimo di detenzione di soli 60 secondi",
    tip2: "Esperienza piattaforma intuitiva e facile da usare",
    tip3: "Tasso di rendimento competitivo",
    tip4: "Pool di flussi di prodotti diversificati stabilizzano i rendimenti"
  },
  "newHome": {
    Cryptos: "Criptomonedas",
    Forex: "Divisas",
    Gold: "Oro",
    futures: "Futuros",
  },
  "newPool": {
    StartStaking: "Iniciar staking"
  },
  "newMe": {
    balance: "Saldo del cuenta",
    saveQr:"Guardar código QR",
    TopUp:"Recompensas de recarga"
  },
  "newLevel": "Nivel",
  "Cumulative":"Monto acumulado actual",
  "levelSets":"Actualizar saldo",
  "kefu":"Contactar con el servicio de atención al cliente para recibir",
  "levelInfo":{
    infoLv1: "Desbloquear recarga y retiro VIP",
    infoLv2: "Desbloquear el recibo mensual de bono VIP de 888",
    infoLv3: "Tener un rendimiento fijo diario del 0.001% en la cuenta de billetera de la plataforma",
    toUpUsd:"Obiettivo di ricarica {name}"
  },
  News:"Noticias",
  StayTuned: "Stay tuned",
  develop: "In sviluppo, attendere prego ~ ~",
  "赠送": "Dono",
  "大礼包": "pacchetto regalo",
  "联系客服领取": "Consultare il servizio clienti",

  meCard: "Mi carta di credito",
  cardAdmin: "Gestione carta di credito",
  newCard: {
    cardName: "Nome banca",
    cardNamePla: "Inserisci il nome della banca",
    number: "Numero conto ricevente",
    numberPla: "Inserisci il numero del conto ricevente",
    name: "Nome del ricevente",
    namePla: "Inserisci il nome del ricevente",
    save: "Salva",
    balance: "Saldo del conto",
    cardInfos: "Informazioni sulla carta di credito",
    customer_service: "Contatta il servizio clienti, orario per il prelievo: 11:00 - 23:00",
    addCardInfo: "Prima aggiungi le informazioni sulla carta di credito"
  },
  status: "Stato",
  Order:"Ordine",

  banTip2: "Si prega di notare che investire è rischioso.",
  langeSet: "Selezione della lingua",
  Sure: "Certo",
  createAcount: {
    have: "Ho già un account",
    goLogin: "Vai alla pagina di login"
  },
  Name: "Nome",
  total_revenue: "Rendimento totale",
  Return: "Ritorno",
  personal: "Centro personale",
  operate: "Operare",
  information: "Informazioni",
  infoRegister:"Si prega di contattare il servizio clienti per confermare che l'indirizzo di ricarica sia corretto prima di trasferire denaro.",

  "模拟交易":"Trading demo",
  "实盘交易":"Trading in tempo reale",
  "模拟资产-订单":"Asset-Trading demo",
  "模拟账户余额":"Trading demo asset"

};
